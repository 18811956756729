import React, { useEffect, useState } from "react"

import Typist from "react-typist"
import styled, { keyframes } from "styled-components"
import PosterPreview from "../poster/PosterPreview"
import postersData from "./romantic"
import Color from "color"

// 1080×1920 Instagram Story

let WIDTH = 1000
let HEIGHT = 1000

if (typeof window !== `undefined`) {
  WIDTH = WIDTH / window.devicePixelRatio
  HEIGHT = HEIGHT / window.devicePixelRatio
}

const INTERVAL = 5000
const posters = postersData.map(p => ({
  ...p,
  backgroundImage: `https://images.unsplash.com/${p.backgroundImage}&auto=format&fit=crop`
}))

const bounceIn = keyframes`
  from {
    transform: scale(.5) rotate(45deg);
  }
  to {
    transform: scale(1) rotate(0deg);
  }
`

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background-color: black;
  left: 0px;
  top: 0px;
  background-size: cover;
  background-position: 50%;
  background-image: url('${p => p.src}');
  transform: scale(1.05);
  transform-origin: 50%;
  transition: opacity 1s, transform 5s ease-out;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: ${p => p.overlay};
  }

  * {
    position: relative;
    z-index: 1;
  }
`

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  position: relative;
  max-width: ${WIDTH}px;
  height: ${HEIGHT}px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
`
const SHADOW_DISTANCE = 6
const Text = styled.div`
  position: relative;
  z-index: 1;
  font-size: 1.1em;
  text-align: center;
  width: ${500}px;
  height: ${140}px;
  top: 20px;
  flex-shrink: 0;
  h1 {
    line-height: 1.3em;
    display: inline;
    text-transform: uppercase;
    font-size: 24px;
    padding: 0.2em 0.4em;
    color: ${p => p.colors.color};
    background-color: ${p => p.colors.backgroundColor};
    box-shadow: ${SHADOW_DISTANCE}px ${SHADOW_DISTANCE}px 0px
      ${p => p.colors.color};
    box-decoration-break: clone;
  }
`

const usePosterSlider = posters => {
  const [index, setIndex] = useState(0)
  const [poster, setPoster] = useState(posters[0])
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = index < posters.length - 1 ? index + 1 : 0
      setIndex(nextIndex)
      setPoster(posters[nextIndex])
    }, INTERVAL)
    return () => clearInterval(interval)
  })

  return { poster, index }
}

const BackgroundFader = ({ posters, index }) => {
  return posters.map((poster, key) => (
    <BackgroundImage
      key={key}
      src={poster.backgroundImage}
      overlay={new Color(poster.posterData.foregroundColor)
        .alpha(0.25)
        .toString()}
      style={{
        opacity: key === index ? 1 : 0,
        transform: key === index ? `scale(1)` : `scale(1.05)`
      }}
    />
  ))
}

const LocationAdd = () => {
  const { poster, index } = usePosterSlider(posters)
  const { posterData } = poster
  const colors = {
    color: posterData.foregroundColor,
    backgroundColor: posterData.backgroundColor
  }
  const posterSize = 700
  return (
    <Wrap>
      <Container>
        <BackgroundFader posters={posters} index={index} />
        {/* <Logo /> */}
        {/* <Stripe
          borderColor={gold}
          backgroundColor={"#000"}
          rotation={2} 
          height={140}
          rotation={-10}
          top={"60vh"}
          height="66%"
        /> */}
        <Text colors={colors}>
          {/* <Emoji key={`emoji-${index}`} colors={colors}>
            <span>{poster.emoji}</span>
          </Emoji> */}
          <Typist key={poster.emoji} cursor={{ show: false }}>
            <h1>{poster.title()}</h1>
            <Typist.Delay ms={1000} />
          </Typist>
        </Text>
        <PosterPreview
          style={{ flexShrink: 0, left: 0, top: -120 }}
          state={posterData}
          width={posterSize}
          height={posterSize}
          transitionIn={true}
          overlayImage={true}
          framed
        />
      </Container>
    </Wrap>
  )
}

export default LocationAdd
