import React from "react"
import LocationAd from "../components/ad/LocationAd"
// import SampleAd from "../components/promo/SampleAd"
import Layout from "../components/layout"

export default () => (
  <Layout>
    {/* <SampleAd /> */}
    <LocationAd />
  </Layout>
)
